<template>
  <div id="controlchart">
    <b-card>
      <highcharts
        :constructor-type="'chart'"
        style="padding-left: 0px"
        :options="options"
      />
    </b-card>
  </div>
</template>
<script>
import { BCard } from "bootstrap-vue";
import { Chart } from "highcharts-vue";
import { watch, toRefs, ref } from "@vue/composition-api";
import exporting from "highcharts/modules/exporting";
import Highcharts from "highcharts";
exporting(Highcharts);
export default {
  props: {
    data: null,
  },
  components: {
    Highcharts: Chart,
    BCard,
  },
  data() {
    return {};
  },
  setup(props) {
    const { data } = toRefs(props);
    const options = ref({
      colors: [
        "#0a6fec", //blue
        "#0ac282", //green
        "#fe9365", //orange
        "#8d77df", //purple
        "#01a9ac", //turqoise
        "#ffd700", //yellow
        "#4da183", //olivedrab
        "#fe5d70",
      ],
      credits: {
        enabled: false,
      },
      scrollbar: { enabled: false },
      rangeSelector: {
        enabled: false,
        selected: 4,
        inputEnabled: false,
        buttonTheme: {
          visibility: "hidden",
        },
        allButtonsEnabled: false,
        labelStyle: {
          visibility: "hidden",
        },
      },

      navigator: {
        visible: false,
        enabled: false,
      },
      chart: {
        type: "spline",
        zooming: { type: "xy" },
        marker: {
          enabled: false,
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal",
              },
              yAxis: {
                left: 20,
                labels: {
                  x: 0,
                  y: 0,
                },
                title: {
                  text: "",
                },
              },
              xAxis: {},
            },
          },
        ],
      },
      boost: {
        useGPUTranslations: true,
      },
      tooltip: {
        xDateFormat: "%Y-%m-%d %H:%M",
        shared: true,
      },
      yAxis: [
        {
          title: "",
          min: 0,
        },
      ],
      legend: {
        enabled: true,
        shadow: false,
      },
      title: {
        text: "",
      },
      ...data.value,
    });
    watch(
      data,
      (newData) => {
        options.value = { ...options.value, ...newData };
      },
      { deep: true }
    );
    return {
      options,
    };
  },
};
</script>
<style scoped lang="scss">
@import "./assets/styling/charts.scss";
</style>
